
















import Vue from 'vue';
import AddWaitlist from './AddWaitlist.vue';
import AddWatchlist from './AddWatchlist.vue';
import ChangeHomeView from './ChangeHomeView.vue';

export default Vue.extend({
  components: {
    AddWaitlist,
    AddWatchlist,
    ChangeHomeView,
  },
  data() {
    return {
      v: '2020-11',
    };
  },
  computed: {
    d(): string {
      return this.$d(new Date(this.v), 'MONTH_YEAR');
    },
  },
});
